import React from "react";
import { Link } from "react-router-dom";
import { Component } from "../../components/Component";
import { Image } from "../../components/Image";
import "./style.css";
import "./styleWithButton.css";


export const Screen12 = () => {
  return (
    <div className="screen-12">
      <div className="desktop-5">
        <div className="overlap-44">
          <div className="banner-3">
            <img className="vector" alt="Vector" src="/img/vector-2.png" />
            <img className="mask-group" alt="Mask group" src="/img/mask-group-4.png" />
            <img className="logo-6" alt="Logo" src="/img/resarch-badge2024-2.png" />
            <div className="button">
              <div className="overlap-group-5">
                <a
                  className="rectangle-3"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSc2MHtkp-xyJewRHGusKrn0GYXrggeoheoW25-OoQRXH-ZnMw/viewform"
                  rel="noopener noreferrer"
                  target="_blank">
                    <div className="text-wrapper-19">Register</div>
                </a>
              </div>
              <Link className="text-wrapper-20" to="/research-details">
                Learn More
              </Link>
            </div>
          </div>
          <div className="navbar-3">
            <Link className="text-wrapper-128" to="/">Home</Link>
            <Link className="text-wrapper-129" to="/research-details">
              Research Details
            </Link>
            <Link className="text-wrapper-130" to="/resources">
              Resources
            </Link>
            <Link className="text-wrapper-131" to="/contact-us">
              Contact
            </Link>
            <div className="rectangle-22" />
          </div>
        </div>
        <div className="overlap-45">
          <div className="group-123">
            <div className="text-wrapper-132">Research Topics</div>
            <img className="line-6" alt="Line" src="/img/line-1-2.svg" />
            <div className="group-124">
              <div className="overlap-group-24">
                <img className="rectangle-23" alt="Rectangle" src="/img/rectangle-15-4.svg" />
                <img className="image-58" alt="Image" src="/img/image-25-1.png" />
                <img className="image-59" alt="Image" src="/img/image-23-1.png" />
                <p className="text-wrapper-133">
                வள்ளுவம் காட்டும் அறிவு<br />
                வள்ளுவம் காட்டும் கோன்மை<br />
                வள்ளுவம் காட்டும் வாய்மை<br />
                வள்ளுவம் காட்டும் புகழ்<br />
                வள்ளுவம் காட்டும் துறவு<br />
                வள்ளுவம் காட்டும் ஊழ்<br />
                வள்ளுவம் காட்டும் கல்வி<br />
                வள்ளுவம் காட்டும் அன்பு<br />
                வள்ளுவம் உணர்த்தும் நிலையாமை<br />
                வள்ளுவம் உணர்த்தும் நற்பண்புகள்<br />
                வள்ளுவம் காட்டும் ஈகை<br />
                வள்ளுவம் உணர்த்தும் மன உறுதி<br />
                வள்ளுவம் காட்டும் கோபம்<br />
                திருக்குறள் காட்டும் பகைவெறி எதிர்ப்பியல்<br />
                திருக்குறளில் கடல்<br />
                வள்ளுவம் காட்டும் உளவு<br />
                திருக்குறள் காட்டும் செல்வம்<br />
                திருக்குறள் காட்டும் மானுடவியல் சிந்தைனகள்<br />
                வள்ளுவம் காட்டும் வாழ்வியல் நெறிமுறைகள்<br />
                வள்ளுவம் காட்டும் உறவும் பகையும்<br />
                மற்றும் பல
                </p>
              </div>
            </div>
            <div className="group-125">
              <div className="overlap-46">
                <img className="rectangle-23" alt="Rectangle" src="/img/rectangle-16-4.svg" />
                <p className="text-wrapper-134">
                வள்ளுவம் காட்டும் அறம்<br />
                வள்ளுவம் காட்டும் தெய்வம்<br />
                வள்ளுவம் காட்டும் அறிவியல்<br />
                வள்ளுவம் காட்டும் நுண்ணறிவு<br />
                திருக்குறளில் உவைமகள்<br />
                வள்ளுவம் காட்டும் நட்பு<br />
                திருக்குறளில் விலங்குகள்<br />
                வள்ளுவம் உணர்த்தும் கற்பு<br />
                வள்ளுவம் உணர்த்தும் நன்றி<br />
                வள்ளுவம் உணர்த்தும் ஒழுக்கம்<br />
                திருக்குறள் காட்டும் அரசியல் முறைகள்<br />
                திருக்குறள் உளவியல் நோக்கு<br />
                திருக்குறளில் குடும்பமும் மனித உறவுகளும்<br />
                திருக்குறளில் சமூகவியல் சிந்தைனகள்<br />
                திருக்குறள் காட்டும் அரசியல் நெறிமுறைகள்<br />
                திருக்குறள் காட்டும் தமிழர் மெய்யியல்<br />
                திருக்குறளும் பெண்களும்<br />
                திருக்குறளும் காட்டும் பொதுவுடைமை<br />
                திருக்குறளில் சுற்றுச்சூழல் சிந்தைனகள்<br />
                வள்ளுவம் உணர்த்தும் முயற்சி
                </p>
                <img className="image-60" alt="Image" src="/img/image-24-1.png" />
                <img className="image-61" alt="Image" src="/img/image-22-1.png" />
              </div>
            </div>
            <p className="text-wrapper-135">2024 தமிழ் ஆராய்ச்சி சில தலைப்புகள் - மாதிரிகள்</p>
          </div>
          <div className="how-it-works-2">
            <div className="overlap-47">
              <p className="text-wrapper-136">
              On March 2024 we are planning to conduct 4th Schaumburg Tamil Fair organised by Thamizh school USA for all Tamil learning students.. The main theme of this fair is திருக்குறள் மற்றும் தமிழர் பண்பாடு. This
will be educational research experience for the parents, kids and visitors. This project is collaboration
among Tamil scholar, grand parents, parents, and students. We give sample project, and some
documents about the topic for a good start. During the Schaumburg Tamil Fair 2024, Each student will be
the representatives of their project, present it through a tri-fold poster board.
              </p>
              <div className="group-126">
                <div className="text-wrapper-137">Register</div>
                <div className="text-wrapper-138">Research</div>
                <div className="text-wrapper-139">Choose Topic</div>
                <div className="text-wrapper-140">Evaluate</div>
                <div className="text-wrapper-141">Paper Presentation</div>
                <div className="text-wrapper-142">How it works?</div>
                <div className="text">{""}</div>
                <img className="image-62" alt="Image" src="/img/image-31.png" />
                <img className="image-63" alt="Image" src="/img/image-32.png" />
                <img className="image-64" alt="Image" src="/img/image-35.png" />
                <img className="image-65" alt="Image" src="/img/image-33.png" />
                <img className="image-66" alt="Image" src="/img/image-34.png" />
                <img className="line-7" alt="Line" src="/img/line-1-2.svg" />
              </div>
            </div>
            <p className="text-wrapper-143">
            The top students for each level will be recognized. Also top students will be showcase their project and recognized by giving
trophies during the 5th World Thirukkural Conference at Chicago conduct by Chicago Tamil
Sangam/IAS/IATR on April 5-7, 2024. We are confident that it would be an invigorating and rewarding
experience for the parents and the students. We would leave it up to parents and students to use their
imagination, creativity and their passion to showcase their talent.
            </p>
            <img className="image-67" alt="Image" src="/img/image-45.png" />
            <div className="group-127">
              <div className="overlap-48">
                <Link to="/resources">
                  <button className="button-11">
                    <div className="overlap-group-25">
                      <div className="text-wrapper-144">2023 Research Articles</div>
                    </div>
                  </button>
                </Link>
                <div className="text-wrapper-145">2023 தமிழ் ஆராய்ச்சி புள்ளிவிவரங்கள்</div>
                <p className="text-wrapper-146">
                  தமிழும் தமிழ் பண்பாடும் மனிதர்களை எவ்வாறு தனி சிறப்புமிக்கவராக மாற்றுகிறது
                </p>
                <div className="group-128">
                  <div className="group-129">
                    <div className="text-wrapper-147">தமிழர் பண்பாடுகள்</div>
                    <div className="text-wrapper-148">28</div>
                    <div className="time-4">
                      <div className="overlap-group-26">
                        <div className="ellipse-16" />
                        <img className="image-68" alt="Image" src="/img/image-37.png" />
                      </div>
                    </div>
                  </div>
                  <div className="group-130">
                    <div className="text-wrapper-149">தமிழ் ஆராய்ச்சி மாணவர்கள்</div>
                    <div className="text-wrapper-150">58</div>
                    <div className="overlap-group-wrapper-2">
                      <div className="overlap-group-27">
                        <div className="ellipse-17" />
                        <img className="image-69" alt="Image" src="/img/image-28.png" />
                      </div>
                    </div>
                  </div>
                  <div className="group-131">
                    <div className="text-wrapper-151">ஆராய்ச்சி காலம்</div>
                    <div className="text-wrapper-152">100 நாட்கள்</div>
                    <div className="overlap-group-wrapper-2">
                      <div className="overlap-group-27">
                        <div className="ellipse-18" />
                        <img className="image-69" alt="Image" src="/img/image-29.png" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="overlap-49">
                  <Image className="image-instance" src="/img/image-35-1.png"><img className="image-70" alt="Image" src="/img/image-36.png" /></Image>                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <Component className="component-3" href="mailto:thamizh.research.usa@gmail.com" />
      </div>
    </div>
  );
};
